body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Permission_functionality_item__2QLa7{
    width: 100%;
    border-radius: 4px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0px;
    /* outline: 1px solid rgb(180, 211, 241); */
    outline:1px solid rgb(244, 244, 244);
}

.Permission_functionality_item_selected__1SN0H{
    width: 100%;
    border-radius: 4px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0px;
    /* outline: 1px solid rgb(180, 211, 241); */
    outline:1px solid rgb(244, 244, 244);
   transition: all 0.3s ease;
    background-color: rgb(244, 244, 244);
}
.Permission_functionality_item__2QLa7:hover{
    transition: all 0.3s ease;
    background-color: rgb(237, 237, 237);

}
.Permission_functionality_drawer_button__1Fy3g{
    position: fixed;
    top: 20px;
    right:0;
}

.Permission_permission_disabled__2g5yi {
    cursor: not-allowed;
    background-color: #dbdada;
    pointer-events: none;
    opacity: 0.7;
    border-radius: 10px;
  }
.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item img {
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;  
  }
  .react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.dropping {
    visibility: hidden;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
  }
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
  
  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }
  
  .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
body {
    padding: 20px;
  }
  
  #content {
    width: 100%;
  }
  
  .react-grid-layout {
    display: flex;
    flex-direction: row;
    background-color: #13697E;
    color: white;
    margin-top: 0%;
    border-radius: 20px 20px 0 0;
    /* justify-content: center; */
    /* height: 40%; */
    /* padding: 4%; */
  }
  
  .layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
  }
  
  .columns {
    -webkit-columns: 120px;
    columns: 120px;
  }
  
  .react-grid-item {
    box-sizing: border-box;
  }
  
  .react-grid-item:not(.react-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
  }
  
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  
  .react-grid-item.static {
    background: #cce;
  }
  
  .react-grid-item .text {
    font-size: auto;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
    cursor: pointer;
    color: black;
  }
  
  .react-grid-item .minMax {
    font-size: 12px;
  }
  
  .react-grid-item .add {
    cursor: pointer;
  }
  
  .react-grid-dragHandleExample {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
  }
  
  .toolbox {
    background-color: #dfd;
    width: 100%;
    height: 120px;
    overflow: scroll;
  }
  
  .hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }
  
  .toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .toolbox__items {
    display: block;
  }
  
  .toolbox__items__item {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    background-color: #ddd;
  }
  
  .droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
  }
  
  
  /* added by vaibhav */
  
  .pv-block-body {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    margin-top: 0%;
    border-radius: 0 0 20px 20px;
  } 
.Payload_PVBlock__3p4ud {
    justify-content: center;
    border-radius: 20px;
    /* border: 1px solid pink; */
    background-color: whitesmoke;
    margin: auto;
    height: auto;
    width: 60%;
    margin-bottom: 2%;
}

.Payload_PVBlockHeader__2HSSD {
    display: flex;
    flex-direction: row;
    background-color: #13697E;
    color: white;
    margin-top: 0%;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    height: 40%;
    padding: 4%;
}

.Payload_HHID__3Q2_M {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.Payload_Title__2c4_3 {
    font-size: 18px;
    font-weight: bold;
}

.Payload_STitle__3riSu {
    font-size: 16px;
}

.Payload_HeaderButtons__13gNc {
    display: flex;
    flex-direction: row;
    padding: 2%;    
}

.Payload_ActionSelect__2IdtE{
    margin-right: 10px;
}

.Payload_PVBLOCKBODY__aH4s- {
    display: flex;
    flex-direction: column;

}
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}



.json-editable-input-box {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 0px;
    border: 0px;
    display: inline;
    font-size: inherit;
    padding:0px 2px;
    outline: none;
}
.json-editor-dropdown-button {
    position: absolute;
    top: 0;
    left: -5px;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out; 
}


.json-key-name{
    position: relative;
    display: inline;
    cursor: pointer;
    padding-left: 10px;
}
.json-key-name:hover .json-editor-dropdown-button{
    opacity: 1;
}

/* .editable-json-key-container{
    background-color: red;
    position: relative;
} */

.add-delete-option{
    display: inline;
    position: absolute;
    top: 0;
    bottom: 0;
    left : 0; 
    background-color: white;
    color: black;
}


.edit-action-container{
    display: inline;
    opacity: 0;
    /* position: absolute; */
    transition: all 0.1s linear;
}

.json-label-container:hover .edit-action-container{
    opacity: 1;
}
.anticon-delete:hover {
    color: red;
    display: inline;
}
.anticon-plus:hover {
    color: rgb(255, 255, 255);
    display: inline;
}

.add-delete-option .anticon {
    transition: color 0.3s ease;
}

.json-editable-input-box:active,.json-editable-input-box:focus  {
    background-color: white;
    /* outline: 1px solid #ccc; */
}
.view_field_form_container{
    border:1px solid #C9C9C9;
    border-radius:10px;
    padding:10px;
    margin:10px 0px;
    padding-bottom:0px;
    padding-top:20px
}
.add_view_field_button{
    margin: auto;
}
.view_field_form{
    position: relative;
}

.delete_view_field{
    position: absolute;
    bottom:23px;
    right: 0px;
}
.transformation_mapping_config_count{
    position: absolute;
    top: 0px;
    right : 0px;
    background-color: rgba(255, 0, 0, 0.567);
    color: white;
    -webkit-user-select: none;
            user-select: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.simple-login-container {
    width: 300px;
    max-width: 100%;
    margin: 50px auto;
}

.simple-login-container h2 {
    text-align: center;
    font-size: 20px;
}

.simple-login-container .btn-login {
    background-color: #0045c7;
    color: #fff;
}

.logo-holder {
    text-align: center;
    padding: 2%;
    margin: 2%;
}

.login-logo {
    width: 50%;
}

.logo30 {
    width: 30%;
}

.logout-btn-holder {
    margin-top: 3%;
}

.card:hover {
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    transition: all 0.3s ease;
    font-weight: bold;
}

.back-button i {
    text-align: center;
    justify-content: center;
}

.logout-button i {
    text-align: center;
    justify-content: center;
}

.create-label {
    margin-top: 3%;
    margin-bottom: 2%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.mymodal {
    display: block !important;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
}

.popuperrtext {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    padding: 5%;
}

.errmessage {
    color: red;
    font-size: 15px;
}

.load-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgb(23 22 22 / 39%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}

.ring-1 {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    padding: 10px;
    border: 7px dashed #4b9cdb;
    border-radius: 100%;
}

.load-4 .ring-1 {
    -webkit-animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
            animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.nb-spinner {
    width: 75px;
    height: 75px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #009688;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

.t10-r10-25 {
    height: 100%;
    width: 30%;
    position: absolute;
    top: 1px;
}

.disabletile {
    opacity: 0.6;
    pointer-events: none;
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgb(23 22 22 / 39%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}

.w30 {
    width: 30%;
}

.w400 {
    width: 340px;
    overflow: scroll;
    display: flex;
}

.pointer {
    cursor: pointer;
    /* width: 100px;
    height: 100px; */

}

.pointer:hover {
    /* border-radius: 100%; */
    color: black;
}

.sortdisplay {
    background-color: white;
}

.break-words {
    word-wrap: break-word;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
    background: #007BFF;
    padding: 6px 12px;
    border-radius: 5px;
    color: #ffffff;
    margin: 1%;
}

.ruleGroup-combinators {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rule-fields {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 2px;
}

.rule-operators {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 2px;
}

.rule-value {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 3px;
}

.rule-remove {
    background: #DC3545;
    padding: 6px 12px;
    border-radius: 5px;
    color: #ffffff;
}

.ruleGroup-remove {
    background: #DC3545;
    padding: 6px 12px;
    border-radius: 5px;
    color: #ffffff;
}

.block-shadow {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 1%;
    margin-bottom: 1%;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 1000px;
    height: 465px; 
  }
  
  .ant-checkbox-group{
    padding: 1% !important;
  }
  

.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  
  .dropdowns-row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .dropdown {
    flex: 1 1;
    min-width: 150px;
  }
  
 
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  
  select{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  Select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  
  
  select option:hover {
    background-color: #007bff;
    color: white;
  }
  

  .jumbotron {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #319ee7;
    color: white;
  }
  
  .jumbotron-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
   
    align-items: right;
  }

 
  
  .buttons {
    display: flex;
    grid-gap: 40px;
    gap: 40px; /* Space between buttons */
    padding-left: 20px;
  }
  
  .jumbotron-header h3 {
    margin: 0;
  }
  
  .jumbotron-content {
    margin-top: 10px;
  }
  
  
  .element {
    color: black;
    margin-top: 5px;
    background-color: #cecece;
    padding: 10px 10px 10px 10px;
    border-radius: 3px;
  }
  
  .options {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
  }
  
    
  .t {
    flex: 1 1;
    min-width: 150px;
  }
  
  .radio-buttons {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .radio-buttons div {
    display: flex;
    align-items: center;
  }
  
  
  @media (max-width: 600px) {
    .dropdowns-row {
      flex-direction: column;
    }
  
    .radio-buttons {
      flex-direction: column;
    }
  }

  .but
  {
   

            display: flex;
            justify-content: center; 
            align-items: center;    
         
 
  }

  button
  {
    background-color: rgb(241, 236, 236);
    
    border: solid  rgb(241, 236, 236) 4px;
    
    cursor: pointer;
    color:black;
  }
  
 .but .update-button :hover
  {
      background-color: rgb(126, 120, 120);
  }

  .preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgb(23 22 22 / 39%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}

.w30 {
    width: 30%;
}





.results-table {
  width: 100%;
  border-collapse: collapse; 
  margin-top: 20px;
}

.results-table th, .results-table td {
  padding: 10px;
  text-align: left; 
  border-bottom: 1px solid #ddd; 
}

.results-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.results-table tr:hover {
  background-color: #f1f1f1; 
}
.info-icon
{
  width: 20px;
  height: 20px;
  padding: 4px 4px 4px 4px;
   background-color: white;
   border-radius: 16px;
   cursor: pointer;
  
}

.custom-modal {
    width: 80% !important; 
    max-width: 800px;
  }
  .dialogInput
{
  width: 180px;

}

#dateInput {
  width: 250px; 
}

.results-table th{
  width: 90px;
}


  .results-table input{
    width: 70px;
  }

  td
  {
    font-size: 13px;
  }

  .modalClass
{
  font-size: 12px;
}

.dropdownMenu,.inputDialog{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

/* .active-row {
  background-color: rgba(234, 237, 234, 0.544);
} */

.active-row {
  background-color: rgba(213, 218, 213, 0.544);
}

.inactive-row {
  background-color: lightcoral;
}

.default-row {
  background-color: white;
}

.image {
  width: 35px;
  height: 35px;
}

.note
{
  padding: 20px 10px 10px 20px;
  color: red;
}




.switch {
    position: relative;
    display: inline-block;
    width:45px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #0e1f2e;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #0682e7;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

