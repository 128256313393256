
.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  
  .dropdowns-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .dropdown {
    flex: 1;
    min-width: 150px;
  }
  
 
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  
  select{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  Select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  
  
  select option:hover {
    background-color: #007bff;
    color: white;
  }
  

  .jumbotron {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #319ee7;
    color: white;
  }
  
  .jumbotron-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
   
    align-items: right;
  }

 
  
  .buttons {
    display: flex;
    gap: 40px; /* Space between buttons */
    padding-left: 20px;
  }
  
  .jumbotron-header h3 {
    margin: 0;
  }
  
  .jumbotron-content {
    margin-top: 10px;
  }
  
  
  .element {
    color: black;
    margin-top: 5px;
    background-color: #cecece;
    padding: 10px 10px 10px 10px;
    border-radius: 3px;
  }
  
  .options {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
    
  .t {
    flex: 1;
    min-width: 150px;
  }
  
  .radio-buttons {
    display: flex;
    gap: 20px;
  }
  
  .radio-buttons div {
    display: flex;
    align-items: center;
  }
  
  
  @media (max-width: 600px) {
    .dropdowns-row {
      flex-direction: column;
    }
  
    .radio-buttons {
      flex-direction: column;
    }
  }

  .but
  {
   

            display: flex;
            justify-content: center; 
            align-items: center;    
         
 
  }

  button
  {
    background-color: rgb(241, 236, 236);
    
    border: solid  rgb(241, 236, 236) 4px;
    
    cursor: pointer;
    color:black;
  }
  
 .but .update-button :hover
  {
      background-color: rgb(126, 120, 120);
  }

  .preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgb(23 22 22 / 39%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}

.w30 {
    width: 30%;
}





.results-table {
  width: 100%;
  border-collapse: collapse; 
  margin-top: 20px;
}

.results-table th, .results-table td {
  padding: 10px;
  text-align: left; 
  border-bottom: 1px solid #ddd; 
}

.results-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.results-table tr:hover {
  background-color: #f1f1f1; 
}
.info-icon
{
  width: 20px;
  height: 20px;
  padding: 4px 4px 4px 4px;
   background-color: white;
   border-radius: 16px;
   cursor: pointer;
  
}
