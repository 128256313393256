.custom-modal {
    width: 80% !important; 
    max-width: 800px;
  }
  .dialogInput
{
  width: 180px;

}

#dateInput {
  width: 250px; 
}

.results-table th{
  width: 90px;
}


  .results-table input{
    width: 70px;
  }

  td
  {
    font-size: 13px;
  }

  .modalClass
{
  font-size: 12px;
}

.dropdownMenu,.inputDialog{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* .active-row {
  background-color: rgba(234, 237, 234, 0.544);
} */

.active-row {
  background-color: rgba(213, 218, 213, 0.544);
}

.inactive-row {
  background-color: lightcoral;
}

.default-row {
  background-color: white;
}

.image {
  width: 35px;
  height: 35px;
}

.note
{
  padding: 20px 10px 10px 20px;
  color: red;
}



