body {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}



.json-editable-input-box {
    height: fit-content;
    min-width: 0px;
    border: 0px;
    display: inline;
    font-size: inherit;
    padding:0px 2px;
    outline: none;
}
.json-editor-dropdown-button {
    position: absolute;
    top: 0;
    left: -5px;
    opacity: 0; 
    transition: opacity 0.3s ease-in-out; 
}


.json-key-name{
    position: relative;
    display: inline;
    cursor: pointer;
    padding-left: 10px;
}
.json-key-name:hover .json-editor-dropdown-button{
    opacity: 1;
}

/* .editable-json-key-container{
    background-color: red;
    position: relative;
} */

.add-delete-option{
    display: inline;
    position: absolute;
    top: 0;
    bottom: 0;
    left : 0; 
    background-color: white;
    color: black;
}


.edit-action-container{
    display: inline;
    opacity: 0;
    /* position: absolute; */
    transition: all 0.1s linear;
}

.json-label-container:hover .edit-action-container{
    opacity: 1;
}
.anticon-delete:hover {
    color: red;
    display: inline;
}
.anticon-plus:hover {
    color: rgb(255, 255, 255);
    display: inline;
}

.add-delete-option .anticon {
    transition: color 0.3s ease;
}

.json-editable-input-box:active,.json-editable-input-box:focus  {
    background-color: white;
    /* outline: 1px solid #ccc; */
}
.view_field_form_container{
    border:1px solid #C9C9C9;
    border-radius:10px;
    padding:10px;
    margin:10px 0px;
    padding-bottom:0px;
    padding-top:20px
}
.add_view_field_button{
    margin: auto;
}
.view_field_form{
    position: relative;
}

.delete_view_field{
    position: absolute;
    bottom:23px;
    right: 0px;
}
.transformation_mapping_config_count{
    position: absolute;
    top: 0px;
    right : 0px;
    background-color: rgba(255, 0, 0, 0.567);
    color: white;
    user-select: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.simple-login-container {
    width: 300px;
    max-width: 100%;
    margin: 50px auto;
}

.simple-login-container h2 {
    text-align: center;
    font-size: 20px;
}

.simple-login-container .btn-login {
    background-color: #0045c7;
    color: #fff;
}

.logo-holder {
    text-align: center;
    padding: 2%;
    margin: 2%;
}

.login-logo {
    width: 50%;
}

.logo30 {
    width: 30%;
}

.logout-btn-holder {
    margin-top: 3%;
}

.card:hover {
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    transition: all 0.3s ease;
    font-weight: bold;
}

.back-button i {
    text-align: center;
    justify-content: center;
}

.logout-button i {
    text-align: center;
    justify-content: center;
}

.create-label {
    margin-top: 3%;
    margin-bottom: 2%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.mymodal {
    display: block !important;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
}

.popuperrtext {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    padding: 5%;
}

.errmessage {
    color: red;
    font-size: 15px;
}

.load-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgb(23 22 22 / 39%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}

.ring-1 {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    padding: 10px;
    border: 7px dashed #4b9cdb;
    border-radius: 100%;
}

.load-4 .ring-1 {
    animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.nb-spinner {
    width: 75px;
    height: 75px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #009688;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

.t10-r10-25 {
    height: 100%;
    width: 30%;
    position: absolute;
    top: 1px;
}

.disabletile {
    opacity: 0.6;
    pointer-events: none;
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: rgb(23 22 22 / 39%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: opacity 0.3s linear;
}

.w30 {
    width: 30%;
}

.w400 {
    width: 340px;
    overflow: scroll;
    display: flex;
}

.pointer {
    cursor: pointer;
    /* width: 100px;
    height: 100px; */

}

.pointer:hover {
    /* border-radius: 100%; */
    color: black;
}

.sortdisplay {
    background-color: white;
}

.break-words {
    word-wrap: break-word;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
    background: #007BFF;
    padding: 6px 12px;
    border-radius: 5px;
    color: #ffffff;
    margin: 1%;
}

.ruleGroup-combinators {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.rule-fields {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 2px;
}

.rule-operators {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 2px;
}

.rule-value {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-right: 3px;
}

.rule-remove {
    background: #DC3545;
    padding: 6px 12px;
    border-radius: 5px;
    color: #ffffff;
}

.ruleGroup-remove {
    background: #DC3545;
    padding: 6px 12px;
    border-radius: 5px;
    color: #ffffff;
}

.block-shadow {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 1%;
    margin-bottom: 1%;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 1000px;
    height: 465px; 
  }
  
  .ant-checkbox-group{
    padding: 1% !important;
  }
  